<!-- 页头部分 -->
<!--<div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>校园招聘<span *ngIf="!config.isByCollege"> - 校园+</span> - 学校详情 - 宣讲会列表</div>-->
<!-- 主体内容部分 -->

<div class="homepage-block">
    <div class="pageTitle"> 校园招聘 - 宣讲会
    </div>
    <!-- <div class="notification">
        <img src="assets/images/noticotion.png" />
        <span>进入工作啦官网，可一站式申请、管理百所高校宣讲会 <span  (click)="gotoOtherCampus()" class="check">点击查看 <img src="assets/images/notMore.png" /></span></span>
    </div> -->
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <div class="college-block" style="margin-bottom:20px;">

    </div>
    <div class="college-block">
        <div style="width: 100%;height: 44px;margin: 20px 0 10px 0;">
            <span style="height: 40px;line-height: 40px;color: #333333;font-size: 20px;float: left">我的申请记录</span>
            <span style="height: 40px;line-height: 40px;color: #5e4fff;font-size: 14px;float: right;cursor: pointer" (click)="gotoAllApplyHistory()">查看全部申请记录 >></span>
        </div>

        <!-- 中间部分 -->
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-camp">
            <img src="assets/images/campnull.png"><br/> 暂无
        </div>
        <!-- 循环列表 -->
        <div class="college-list-cont" *ngIf="countList>0">
            <div class="listCell" *ngFor="let n of campusList;let i = index;">
                <div class="listCellContent" (click)="goPreach(n)">
                    <div style="width: 90px;height: 100%;float: left">
                        <img src="{{n.logo}}" style="width:60px;height: 60px;margin-left: 15px;margin-top: 15px;border-radius: 30px">
                    </div>
                    <div style="height:60px;width: 25%;margin-top: 20px;float: left;overflow:hidden">
                        <div style="height: 25px;line-height: 25px;font-size: 18px;color: black;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width: 360px;">{{n.name}}</div>
                        <p class="milkRoundAddressAndTime">
                            <img src="../../assets/images/addressIcon.png" style="width: 12px;height: 16px;margin-top: -3px"> {{(!n.place_name?"地点暂无":n.place_name)}}
                        </p>
                    </div>
                    <div style="width: 20%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                        <span>{{'时间:'+(n.start_time | date:"yyyy-MM-dd HH:mm"+'&nbsp;-&nbsp;'+(n.end_time | date:"yyyy-MM-dd HH:mm"))}}</span>
                    </div>
                    <div style="width: 15%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                        <span *ngIf='n.college_name'>{{n.college_name==null?"":n.college_name}}</span>
                    </div>
                    <div style="width: 30%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                        <button class="cellButton" (click)="goUpdata(n.collegeid,n)" *ngIf="(n.audit_status=='to_audit'|| n.cooperation_status=='1')&& !n.fail">审核中</button>
                        <button class="cellButton shibai" (click)="openRevoke($event,n.ctid)" *ngIf="(n.audit_status=='to_audit'|| n.cooperation_status=='1')&& !n.fail">撤回</button>
                        <button class="cellButton" (click)="goUpdata(n.collegeid,n)" *ngIf="n.audit_status == 'revoke'">重新预约</button>
                        <button class="cellButton" (click)="goUpdata(n.collegeid,n)" *ngIf="n.fail">预约失败</button>
                        <button class="cellButton" *ngIf="n.audit_status=='passed'&& n.cooperation_status=='2'">预约成功</button>
                        <button class="cellButton" *ngIf="n.audit_status=='passed'&& n.cooperation_status=='2'" (click)="showJoinCerModel($event,n)">获取参会凭证</button>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>

    <div class="collegeInfo" *ngIf="college">
        <img class="collegelogo" *ngIf="college.logo" [src]="college.logo" alt="高校logo">
        <img class="collegelogo" *ngIf="!college.logo" src="assets/images/none.png" alt="高校logo">
        <div class="center">
            <div class="row flex_s">
                <div><span>学校名称：</span>{{college.name}}</div>
                <div><span>联系邮箱：</span>{{college.email}}</div>
            </div>
            <div class="row flex_s">
                <div><span>所在城市：</span>{{college.region_text}}</div>
                <div><span>学校代码：</span>{{college.code}}</div>
            </div>
            <div class="row flex_s">
                <div><span>学校性质：</span>{{college.school_nature_text}}</div>
                <div><span>创办时间：</span>{{college.establish_time | date:"yyyy-MM-dd"}}</div>
            </div>
        </div>
        <div>
            <button class="sign gray" [class.purplepro]="college.campustalk_switch" (click)="statusSign()">申请宣讲会</button>
            <div class="down" *ngIf="college.audit_switch.department_receipt_url" (click)="downLoadField()">下载参会回执</div>
        </div>
    </div>


</div>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowJoinCerModel" [style]="{'height':532,'minHeight':300,'width':300,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="JoinCerModel">
        <img class="title" src="../../assets/images/campjointitle.png">
        <div class="line"></div>
        <div class="subtitle">宣·讲·会·参·会·企·业·凭·证</div>
        <div class="info" *ngIf="JoinCerModel">
            <div class="camptitle">{{JoinCerModel.name}}</div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_comname.png" />
                <div class="rightText">
                    企业名称: {{JoinCerModel.department_name}}
                </div>
            </div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_time.png" />
                <div class="rightText">
                    宣讲时间: {{JoinCerModel.start_time | date:"yyyy-MM-dd HH:mm"}}
                </div>
            </div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_address.png" />
                <div class="rightText">
                    宣讲地点: {{JoinCerModel.place_name}}
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
    <div class="showTopingBox">
        <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/contactMeClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">撤回报名</div>
            </div>
        </div>
        <div class="p2">确定撤回该宣讲会报名吗？</div>
        <div class="btns">
            <div class="btn active" (click)="revokeModule = false">取消</div>
            <div class="btn blueStreamer" (click)="downMilk()">确定</div>
        </div>
    </div>
</p-dialog>