<div class="top-header">
    <div class="left">
        <span class="college-department" *ngIf="config.isByCollege"><img [src]="config.byCollegeLogo">{{config.byCollegeName}}</span>
        <span *ngIf="!config.isByCollege"><img src="assets/images/logo.png"></span> 管理中心
        <b></b>企业版
    </div>

</div>

<div class="contentPsw">

    <p class="titlePsw"><b>找回密码</b></p>
    <div style="height: 3px;background-color: #5d479a;width: 100px"></div>

    <div style="width: 500px" *ngIf="tap==1">
        <div class="sign-list">
            <input type="text" placeholder="请填写邮箱" [(ngModel)]="findPswModel.email" style="width: 450px">
        </div>

        <div class="sign-list">
            <input [(ngModel)]="vcodeImg" style="width: 300px" type="text" placeholder="请输入图片验证码" />
            <img (click)="getCode()" *ngIf="imgCode" [src]="imgCode" style="width: 150px;height: 50px;margin-left: 0px;margin-top: -5px">
            <button (click)="getCode()" *ngIf="!imgCode" class="emailBtn">点击获取验证码</button>
        </div>

        <div class="sign-list">
            <input type="text" placeholder="请填写邮箱验证码" [(ngModel)]="findPswModel.emailCode" style="width: 300px">
            <button class="emailBtn" (click)="getEmailCode()">{{sengCodeBtn}}</button>
        </div>
        <!-- <div class="sign-list flex_r" style="padding-right: 40px">
      <span style="cursor: pointer;color: #5E4FFF" (click)="gotoFindAccount()"> >> 账号找回</span>
    </div> -->

        <button class="finishBtn" (click)="cancel()">取消</button>

        <button class="finishBtn" (click)="nextTap(2)">下一步</button>
    </div>

    <div style="width:500px" *ngIf="tap==2">
        <div class="sign-list">
            <input type="password" placeholder="请填写新密码" [(ngModel)]="findPswModel.oldpsw" style="width: 450px">
        </div>
        <div class="sign-list">
            <input type="password" placeholder="再次输入新密码" [(ngModel)]="findPswModel.newpsw" style="width: 450px">
        </div>
        <div style="color: #999999;font-size: 12px;margin-left: 10px;margin-top: 5px">格式：长度8-16位，由不连续的字母和数字2种构成 </div>

        <button class="finishBtn" (click)="nextTap(1)">上一步</button>
        <button class="finishBtn" (click)="finishClick()">完成</button>
    </div>

</div>