import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataApi, Local, Constants, Config, Opera, MyMMessage, NotEmitTool} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from  '@angular/router';
import { Events } from '../../provider/eventService';

declare var WxLogin: any;
declare let wx: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  public config: Config;
  public countDown:any;//倒计时
  public isSendOver = false;//是否发送过验证码
  public isSendIng = false;//是否为正在发送中
  public clearButton = false;//是否显示清空按钮
  public clearCode = false;//是否显示清空验证码
  public loginName: string;//手机号/账号
  public vcodeText: string;//验证码
  public vcodeImg:any;//图片验证码（用户填写）
  public imgCode:any;//图形验证码
  public imgCodeId:any;//图形验证码id
  public isRememberAccount=false;//是否选中记住账号
  public isRememberAccount1=false;//是否选中记住邮箱账号
  public loginType='email';//登陆方式phone/email
  public loginEmail='';//登录邮箱
  public loginEmailPsw='b-lite-gw';//登录邮箱密码

  public  updateLoginEmailPsw = false;
  public seePswType2='password';
  public seePswType3='password';
  public updatePswData={
    oldPsw:'',
    newPsw:'',
    okPsw:'',
  };
  public wxBindModel = {
    loginName:'',
    vcodeText: '',
    vcodeImg:'',
    unionid:''
  }
  public CollegeDetails = null;
  public noneBindWxChips = false; // 微信扫码登录 未找到账号的弹，引导注册/绑定
  public isShowRegister = false; // 是否展示注册的模块
  public isShowBindModel = false; // 是否展示绑定的账号/二维码的弹窗

  public showWXCode = false;
  public register_source = "";

  //登录完成跳转双选会
  public isType = '';
  public loginmrId = '';

  constructor(public dataApi: DataApi,
              public local:Local,
              public opera:Opera,
              private sanitizer: DomSanitizer,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public mimc:MyMMessage,
              public toastr: ToastrService,
              public notEmitTool:NotEmitTool,


              public events: Events) {
      this.config = Config.getInstance();//实例化config
      this.config.isLogin=true;
   }

   public aa = new EventEmitter();
  ngOnInit() {
    if(document.referrer) {
      if (document.referrer.indexOf('air')>=0){
        this.register_source = 'b-lite-sxh'
      }else {
        this.register_source = 'b-lite-gw'
      }
    }
    let hash = location.hash;
    console.log('hash',hash)
    let arr = hash.split('?')
    let collegeid_url  = '';

    var docWidth = document.body.clientWidth;
    this.config.isScreenSmallBox = docWidth < 1680 ? true : false;
    if(!this.config.isDoubleCollegeClearLoginInfo){
      this.dataApi.logout();

    }
    this.bro();
    let openid = '';
    let unionid = '';
    let auth_code = "";
    let errorcode = "";
    if(arr.length>1) {
      let urlStr = arr[1];
      let itemAr = urlStr.split('&');
      console.log('itemAr',itemAr);
      for (let item of itemAr) {
        let dataArr = item.split('=');
        if(dataArr.length>=2) {
          if(dataArr[0]=='id') {
            collegeid_url = dataArr[1]
          }
          if(dataArr[0]=='openid') {
            openid = dataArr[1]
          }
          if(dataArr[0]=='unionid') {
            unionid = dataArr[1]
          }
          if(dataArr[0]=='errorcode') {
            errorcode = dataArr[1]
          }
          if(dataArr[0]=='auth_code') {
            auth_code = dataArr[1]
          }
          if(dataArr[0]=='type') {
            this.isType = dataArr[1]
            this.local.set('isType',this.isType);
          }
          if(dataArr[0]=='mrId') {
            this.loginmrId = dataArr[1]
            this.local.set('loginmrId',this.loginmrId);
          }
        }
      }
    }

    if(collegeid_url) {
      this.local.set('collegeid',collegeid_url);
      this.getCollege(collegeid_url);
      this.config.current_Collegeid = collegeid_url
    }
    else {
      let collegeid=this.local.get('collegeid');
      if(!collegeid) {
        this.toastr.error(this.dataApi.getNoDepardmentID());
        return;
      }
      else if(collegeid) {
        if(collegeid==undefined){
          this.toastr.error(this.dataApi.getNoDepardmentID());
          return;
        }
        this.config.current_Collegeid = collegeid

        this.getCollege(collegeid);
      }
    }

    // 获取验证码
    this.getCode();
    //初始化赋值
    if(this.local.get(Constants.loginName)){
      this.loginName=this.local.get(Constants.loginName);
      this.isRememberAccount = true;
    }
    if(this.local.get(Constants.loginEmail)){
      this.loginEmail=this.local.get(Constants.loginEmail);
      this.isRememberAccount1 = true;
    }

    if(auth_code.length>0) {
      this.weichatLogin(auth_code);
      this.wxBindModel.unionid = auth_code
    }
    if(errorcode.length>0) {
      this.toastr.error('当前微信未绑定账户')
    }
    setTimeout(()=>{
      this.initWeiChatLogin()
    },600)
  }


  // 获取图形验证码
  getCode(){
    this.dataApi.getImgCode().then((res:any)=>{
      this.imgCode='data:image/png;base64,'+res.vcode_image;//验证码图片base64
      this.imgCodeId=res.vcodeid;
    }).catch((err)=>{
      //console.log(err);
    })
  }

  // 发送验证码(+验证手机号是否可用)
  postMobileSmsVcode(){
    if(!this.loginName || this.isSendIng){
      return;
    }
    if(!this.vcodeImg){
      this.toastr.error("请填写图片验证码");
      return;
    }
    this.loginName = this.loginName.replace(/\s*/g,"");
    console.log(this.loginName)
    this.isSendIng = true;

    let phoneModel={
      format_type:0,//0:手机号，1：手机号&固话；2.邮箱
      data:this.loginName
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning("手机号码格式不正确");
        this.loginName='';
        this.isSendIng = false;
        return;
      }else{
        //判断港澳台地区手机号 并判断访问高校地址
        let filter = /^[0][0][8][5][2]\d{8}$|^[8][5][2]\d{8}$|^\d{8}$|^[0][0][8][5][3]\d{8}$|^[8][5][3]\d{8}$/;
        if (filter.test(this.loginName)){
          this.dataApi.getCollageDetails(this.local.get('collegeid')).then((res:any)=>{
            if(res && res.region){
              var num = res.region.slice(0,2)
              if(num == '32' || num == '33' || num =='34' || num == '35'){
                this.sendCode();
              }else{
                //高校地址为大陆
                this.toastr.error('该校不对港澳台企业开放招聘');
                this.isSendIng = false;
              }
            }
          }).catch((error)=>{

          })
        }else{
          this.sendCode();
        }
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    });
  };

  //发送验证码接口调用
  sendCode(){
    let _params = {
      "mobile": this.loginName,
      "sms_type": "login",
      "vcode_verify": {
          "vcodeid": this.imgCodeId,
          "vcode_text":this.vcodeImg
      }
    }
    this.opera.loading();
    this.dataApi.postMobileSmsVcode(_params).then((res:any)=>{
      // this.hideClearButton();
      this.opera.loadiss();
      this.toastr.success('验证码已发送，请注意查收');
      let count = 60;
      this.countDown = count + 's';
      this.isSendOver = true;
      let timer = setInterval(()=>{
        count -= 1;
        this.countDown = count + 's';
        if (count === 0) {
          clearInterval(timer);
          this.countDown = '';
          this.isSendIng = false;
        }
      }, 1000);
      //console.log(res);

    }).catch((error)=>{
      this.opera.loadiss();
      //console.log(error);
      this.toastr.error(error);
      this.isSendIng = false;
    });
  }

  gotoRegister(){
    this.loginType = 'phone'
    this.postMobileSmsCode()
  }

  //通过手机验证码登录
  postMobileSmsCode(){

    let collegeid = this.local.get('collegeid');
    console.log("请重新从高校站内登录code:1",collegeid);
    if(!collegeid) {
      console.log("请重新从高校站内登录code:2",collegeid);
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }
    if(collegeid==undefined){
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }

    if(this.loginType=='email') {
      this.emailLogin();
      return;
    }
    if (!this.loginName) {
      this.toastr.error('手机号码不能为空');
        return;
    }
    if (!this.vcodeText) {
      this.toastr.error('验证码不能为空');
        return;
    }else if(String(this.vcodeText).length<4){
      //console.log( typeof(this.vcodeText));
      let n=4-String(this.vcodeText).length;
      let textNum="0000";
      this.vcodeText=textNum.substring(0,n)+this.vcodeText;
      //console.log(this.vcodeText);
    }

    //判断用户是否选中保存账户
    if(this.isRememberAccount){
      this.dataApi.setLocalLoginName(this.loginName);//存储账号到本地(接口完善后再放到请求成功的回调中去)
    }else{
      this.local.remove(Constants.loginName);
    }

    let loginData = {
      mobile:this.loginName,
      smscode:this.vcodeText,
      login_type:'sms',
      register_source: this.register_source,//注册来源
      createip: "",
      project_source: this.register_source,//项目来源
      login_source: this.register_source//登陆来源
    }
    //通过账号和验证码获取token
    console.log('登陆',loginData);
    this.dataApi.accountLogin(loginData).then((res:any)=>{
      //loader.dismiss();
      if(res){
        //通过code换取tooken
        this.dataApi.postCodeTradeToken({
          "grant_type": "authorization_code",
          "code": res.value
        }).then( async (res:any)=>{
            if(res){
              console.log("token数据------------login",res);
              //console.log(res);
              let refreshToken ={
                refresh_token:res.refresh_token,//给全局变量赋值----刷新token
                exprid_time:new Date().getTime()+res.expires_in*1000//token过期时间(毫秒)
              };
              this.dataApi.setRefreshToken(refreshToken);
              this.dataApi.setLocalToken(res.access_token);
              this.local.set(Constants.accesstoken,res.access_token);
              this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息

                  console.log('getDepartment',res);
                  if(res){//--------------------------------------企业信息存在（跳转首页）
                    //之前的逻辑-----------------

                    if(res.employer_category != '0101') {//港澳台企业
                      if (this.CollegeDetails) {
                        if (this.CollegeDetails.region) {
                          var num = this.CollegeDetails.region.slice(0, 2)
                          if (this.isDaluCollege(num)) {//高校地址为大陆
                            this.toastr.error('该校不对非中国大陆企业开放招聘');
                            return;
                          }
                        }
                      }
                    }

                    this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
                    this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量

                   // this.mimc.init(res.jm_username);//登陆mimc
                    this.local.remove(Constants.loginRs);//清除本地注册来源存储

                    // 4-8新逻辑----
                    if(res.linkman=='') {
                      this.router.navigateByUrl('guide-company');
                    } else  if(res.auth.length==0) {
                      //判断企业营业执照，有的话说明已经通过第三步，没有的话跳转到第三步
                      this.router.navigateByUrl('identity');
                    } else {
                      this.router.navigateByUrl('home');
                    }

                  }else{//-------------------------------------企业信息不存在（进入引导）
                    //console.log('去引导');
                    setTimeout(() => {
                      this.toastr.info('请完善基本信息');
                    },600);
                    this.router.navigateByUrl('guide');
                  }

              }).catch((error)=>{
                //console.log(error);
                setTimeout(() => {
                  this.toastr.error(error+',错误码:L006');
                },600);
              });

            }else{
              setTimeout(() => {
                this.toastr.error('验证码错误，或已过期,错误码:L002');
              },600);
            }
        }).catch((error)=>{
          //console.log(error);
          setTimeout(() => {
            this.toastr.error('验证码错误，或已过期,错误码:L003');
          },600);
        });

      }else{
        setTimeout(() => {
          this.toastr.error('验证码错误，或已过期,错误码:L004');
        },600);
      }
    }).catch((error) => {
      //console.log(error);
      if(error.indexOf("手机号码格式错误")>=0) {
        this.toastr.error("手机号码格式错误");
        return;
      }
      console.log(error,'error');
      setTimeout(() => {
        this.toastr.error(error);//出现错误请重新获取验证码,错误码:WL005
      },600);
    });

  }

  //获取用户信息
  getUser(){
    this.dataApi.getUser().then((res:any)=>{
      //console.log("线上数据hr信息------------------------aboutsettinguser");
      //console.log(res);
      if(res){
        this.config.loginUser=res;
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
      }
    }).catch((error)=>{
        this.toastr.error(error+'L008');
    });
  }

  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.postMobileSmsCode();
    }
  }

  /**
   * 登录方式切换
   * @param type == phone/email
   */
  changeLoginType(type,e){
    if(type==this.loginType) {return;}
    console.log('e',e)
    this.vcodeImg = '';//清空手机验证码输入框
    this.getCode();// 重新获取验证码
    this.loginType=type;
    this.showWXCode = false
  }

  gotoFindPsw(){
    this.router.navigateByUrl('app-find-psw');
  }
  /**
   * 邮箱登录网络请求
   */
  emailLogin(){
    let collegeid=this.local.get('collegeid');
    console.log("请重新从高校站内登录 code:3",collegeid);
    if(!collegeid) {
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }
    if(this.loginEmail==''||this.loginEmailPsw==''||this.vcodeImg=='') {
      this.toastr.error('信息不完整');
      return;
    }
    let loginSource;
    if(this.local.get(Constants.loginRs)){
      loginSource=this.local.get(Constants.loginRs);
    }else{
      loginSource='web';
    }

    let data = {
      login_type:'email',
      login_email:this.loginEmail,
      password:this.loginEmailPsw,
      register_source:loginSource,
      login_source: loginSource,//登陆来源
    };
    console.log("emailModel",data);

    if(this.isRememberAccount1){
      this.dataApi.setLocalLoginEmail(this.loginEmail);//存储账号到本地(接口完善后再放到请求成功的回调中去)
    }else{
      this.local.remove(Constants.loginEmail);
    }

    //验证图片验证码//
    if(!this.vcodeImg){
      this.toastr.error("请填写图片验证码");
    }
    let checkPicCode ={
      "vcodeid": this.imgCodeId,
      "vcode_text": this.vcodeImg
    }
    this.dataApi.emailLoginCheckPicCode(checkPicCode).then((res:any)=>{
      //图片验证成功
      this.dataApi.accountLogin(data).then((res:any)=>{
        console.log("邮箱登录",res);
        if(res.value) {
          this.codeGetToken(res.value)
        } else {
          this.toastr.error("邮箱登录失败")
        }
      }).catch((err)=>{
        console.log("邮箱登录失败",err);
        this.toastr.error(err);
      })

    }).catch((err)=>{
      console.log("图片验证失败",err);
      this.toastr.error(err);
    })
  }
  
  isEmail(test){
    return /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(test);
  }

  /**
   * 登录方式，拿code换token
   * @param code
   */
  codeGetToken(code){
    this.dataApi.postCodeTradeToken({
      "grant_type": "authorization_code",
      "code": code
    }).then( async (res:any)=>{
      if(res){
        console.log("token数据------------login",res);
        //console.log(res);
        let refreshToken ={
          refresh_token:res.refresh_token,//给全局变量赋值----刷新token
          exprid_time:new Date().getTime()+res.expires_in*1000//token过期时间(毫秒)
        };
        this.dataApi.setRefreshToken(refreshToken);
        this.dataApi.setLocalToken(res.access_token);
        this.local.set(Constants.accesstoken,res.access_token);
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
          console.log('登录getDepartment',res,this.CollegeDetails);

          if(res){//--------------------------------------企业信息存在（跳转首页）

            if(res.employer_category != '0101') {//港澳台企业
              if (this.CollegeDetails) {
                if (this.CollegeDetails.region) {
                  var num = this.CollegeDetails.region.slice(0, 2)
                  if (this.isDaluCollege(num)) {//高校地址为大陆
                    this.toastr.error('该校不对港澳台企业开放招聘');
                    return;
                  }
                }
              }
            }

            //之前的逻辑-----------------
            this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
            this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
            // this.getImInit();//初始化极光IM信息(init-web测试版)

            // this.mimc.init(res.jm_username);//登陆mimc
            this.local.remove(Constants.loginRs);//清除本地注册来源存储

            // 4-8 新逻辑----
            if(res.linkman=='') {
              this.router.navigateByUrl('guide-company');
            } else  if(res.auth.length==0) {
              //判断企业营业执照，有的话说明已经通过第三步，没有的话跳转到第三步
              this.router.navigateByUrl('identity');
            } else {
              if(this.loginEmailPsw=='Asd123456') {
                this.toastr.warning('登录成功，当前用户安全等级较低，请立即修改密码')
                this.updateLoginEmailPsw = true;
              } else {
                // if(this.isType && this.loginmrId){ 
                //    this.local.set(Constants.mrid, this.loginmrId);
                //    this.router.navigate(['home/college-milk-sign']);
                //    this.local.remove('isType');
                //    this.local.remove('loginmrId');
                //    return
                // }
                
                this.router.navigateByUrl('home');
              }
            }

          }else{//-------------------------------------企业信息不存在（进入引导）
            //console.log('去引导');
            setTimeout(() => {
              this.toastr.info('请完善基本信息');
            },600);
            this.router.navigateByUrl('guide');
          }

        }).catch((error)=>{
          //console.log(error);
          setTimeout(() => {
            this.toastr.error(error+',错误码:L006'+error);
          },600);
        });
      }else{
        setTimeout(() => {
          this.toastr.error('验证码错误，或已过期,错误码:L002');
        },600);
      }
    }).catch((error)=>{
      //console.log(error);
      setTimeout(() => {
        this.toastr.error('验证码错误，或已过期,错误码:L003,'+error);
      },600);
    });
  }

  closeModel(){
  }

  isPsw(psw){
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,25}$/;
    return reg.test(psw)
  }
  changeSeePsw(index,type){
    if(index==2) {
      this.seePswType2 = type;
    }
    if(index==3) {
      this.seePswType3 = type;
    }
  }
  savePsw(){
    if(this.updatePswData.newPsw==''||this.updatePswData.okPsw==''){
      this.toastr.error("密码为空！");
      return;
    }
    if(this.updatePswData.newPsw.length<6) {
      this.toastr.error("密码不能小于6位！");
      return;
    }
    if(!this.isPsw(this.updatePswData.newPsw)) {
      this.toastr.error('密码格式错误');
      return;
    }
    if(this.updatePswData.newPsw!=this.updatePswData.okPsw) {
      this.toastr.error("两次密码不一致");
      return;
    }

    let data = {
      oldpassword:'Asd123456',
      newpassword:this.updatePswData.newPsw,
    };

    this.dataApi.changePsw(data).then((res:any)=>{
      console.log("修改成功",res);
      this.toastr.success('修改成功！');
      this.router.navigateByUrl('home');

      this.updateLoginEmailPsw = false;
    }).catch((err)=>{
      console.log("修改邮箱失败",err);
      if(err.indexOf('password')>=0) {
        this.toastr.error('密码格式错误，请重新填写')
        return;
      }
      this.toastr.error(err);return;
    })
  }
  afterSettingPsw(){
    this.router.navigateByUrl('home');
  }
  getCollege(id){
    if(!id) {
      return;
    }
    this.local.set(Constants.collegeid,id);
    // this.loading();
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      console.log("获取到高校信息---------");
      console.log(res);
      setTimeout(() => {
        this.config.isByCollege=true;
      });
      this.CollegeDetails = res;
      this.local.set(Constants.byCollegeDetails,JSON.stringify(res));
      this.config.byCollegeLogo=res.logo;
      this.config.byCollegeName=res.name;
    }).catch((err)=>{
    });
  }
  public isDaluCollege(College){

    if(College =="32"||College =="33"||College =="34"||College =="35") {
      return false;
    }
    return true;
  }
  //关闭屏幕分辨率小的弹窗
  closescreenModel(){
    this.config.isScreenSmallBox = false;
  }
  bro() {
    // 谷歌内核
    console.log('浏览器信息',navigator)
    if(navigator.userAgent) {
      if(navigator.userAgent.indexOf("Chrome")>=0) {
        return;
      }
      this.config.chrome_core = true;
    }

    //各种浏览器的userAgent收集
    //https://blog.csdn.net/m0_37499059/article/details/79003731
  }
   initWeiChatLogin(){
    let collegeid = localStorage.getItem('collegeid');
    this.showWXCode = true;
    let appid = 'wx5050e06cd7e3ac48';
    let api = '';
    let wxUrl = '';
    if(this.config.api=='https://webapi.goworkla.cn' || this.config.api=='https://webapinet6.goworkla.cn' || this.config.api=='https://apinet6.goworkla.cn') {
      api = 'https://employerc.goworkla.cn/#/login';
      wxUrl = 'http://wx.goworkla.cn/Open/AuthorizeLogin?t=d&redirect_uri='
    } else if(this.config.api=='http://webapi.test.goworkla.cn' || this.config.api=='http://webapi2.test.goworkla.cn') {
      // http://employerc.test.goworkla.cn/#/login?type=login&id=5ab99b55d041fd4014a3b8f4
      api = 'http://employerc.test.goworkla.cn/#/login';
      wxUrl = 'http://wx.goworkla.cn/Open/AuthorizeLogin?t=d&online=false&redirect_uri='
    } else {
      //本地暂无配置微信扫码登录
    }

    api = this.urlencode(api);
    let redirect_uri = wxUrl+api;
    redirect_uri =  this.urlencode(redirect_uri);
    console.log('urlencode',redirect_uri)
    setTimeout(()=>{
      var obj = new WxLogin({
        self_redirect:false,
        id:"login_container",
        appid: appid,
        scope: "snsapi_login",
        redirect_uri: redirect_uri,
        state: "",
        style: "black",
        // href:"data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9"
     });
    },300)
  }
  urlencode (str) {
    str = (str + '').toString();
    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
    replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
  }
  weichatLogin(unionid){
    this.codeGetToken(unionid)

    // let model = {
    //   unionid:unionid,
    //   login_type: 'wechat_open', // 登陆来源
    //   account_type:'department',
    //   login_source:'pc',
    // };
    // this.dataApi.accountLogin(model).then((res:any)=>{ //通过tooken获取企业信息
    //   if(res) {
    //     this.codeGetToken(res.value)
    //   }
    // }).catch((error)=>{
    //   this.toastr.error(error)
    //   // this.loginType = 'scan'
    //   if(error=='您还未绑定微信，请用手机号码登录') {
    //     setTimeout(() => {
    //       this.noneBindWxChips = true
    //     },600);
    //   }
    // });
  }

  /**
   * type  register注册  bind 绑定
   * @param type
   */
  noneBindWxChipClick(type){
    if(type === 'register'){
      this.noneBindWxChips = false
      this.isShowRegister = true
    }else {
      this.noneBindWxChips = false;
      this.isShowBindModel = true;
    }
  }
  // 微信账号绑定
  bindWxClick(){
    if(this.loginName==''||this.vcodeImg=='' || this.vcodeText==''){
      return
    }

    if(this.wxBindModel.unionid.length==0) {
      this.toastr.warning('请重新扫码登录绑定')
      return;
    }
    let data = {
      unionid:this.wxBindModel.unionid,
      mobile_verify:{
        mobile:this.loginName,
        vcode_text:this.vcodeText
      }
    };
    this.dataApi.userBindWeChat(data).then((res:any)=>{//通过tooken获取企业信息
      if(res) {
        this.toastr.success('绑定成功，正在登录中...')
        this.isShowBindModel = false;
        this.codeGetToken(res.value)
      }
    }).catch((error)=>{
      // this.isShowBindModel = false;
      this.toastr.error(error)
    });
  }
  // 记住邮箱账号
  isRememberAccountClick(){
    this.isRememberAccount1=! this.isRememberAccount1;
    if(this.isRememberAccount1){
    }else {
      this.local.remove(Constants.loginEmail);
    }
  }
  // 关闭微信绑定提示
  closeWeixinBingChips(){
    this.noneBindWxChips = false;
  }
}
